import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  TermsHeaderComponent,
  TermsTextBodyComponent,
  TermsContainer,
  TermsListBodyComponent,
  TermsBoldText,
} from "../../components/TermsComponent/TermsComponent";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="terms-page">
      <Helmet>
        <title>Privacy Policy - Penta Money</title>
      </Helmet>

      <div className="terms-wrapper desktop-max-width-smaller">
        <div className="terms-intro">
          <p className="extra-21">Penta Money Privacy Policy</p>
          <p>Last Edited: February 9, 2023</p>
        </div>
        <TermsTextBodyComponent>
          <p>
            At Penta, accessible at www.penta.money, one of our main priorities
            is the privacy of our visitors.
            <br />
            Privacy statement for Penta Money (also known as "Penta," "we," or
            "our"). Any references to "you," "your," or "user" refer to visitors
            to and users of the Penta website and software. We are dedicated to
            preserving your personal information because we appreciate your
            right to privacy. We have created this privacy policy to inform you
            of our practices regarding the personal data (as defined below) that
            we collect, use, and share in connection with the Penta website,
            mobile app, and other software supplied on or in conjunction with
            our services, as outlined in our Terms of Use (collectively, the
            "Platform or Services").
          </p>
        </TermsTextBodyComponent>

        <ul className="terms_list_body_contaner">
          <TermsContainer>
            <TermsHeaderComponent>Consent</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                By accessing or using our platform in any way, you consent to
                the collection, storage, sharing, and use of your information as
                indicated in this Policy and our Terms of Service. You may
                withdraw your consent in writing at any time, but this does not
                affect our right to process your data prior to your withdrawal.
                <br />
                This Privacy Policy applies to our online activities and is
                valid for visitors to our website and mobile app with regard to
                the information that they share and/or collect in Penta.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Information we collect</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Personal information is defined as information that can
                reasonably be used to identify, contact, describe, link, or
                reasonably be linked to a specific person or household for the
                purposes of this privacy policy. Personal Data and Personally
                Identifiable Information are both terms used to describe
                information that is also considered to be personal information
                in some jurisdictions. De-identified or anonymized data that
                cannot be used to identify you is not covered by this privacy
                statement. The following categories of personal data are among
                those we may gather, keep, use, and transfer:
                <br />
                <br />
                <TermsBoldText> Identity </TermsBoldText> : information
                including name, address, email, phone, mobile number, date of
                birth, geolocation, information from official identification
                documents like a driver's license or passport, as well as age,
                gender, and nationality;
                <br />
                <br />
                <TermsBoldText> Transactional Information </TermsBoldText>
                includes addresses for your Fiat and Non-Fiat wallets, details
                about your Penta account, information about your Fiat and
                Non-Fiat trading activities, and related details for deposits
                and withdrawals.
                <br />
                <br />
                <TermsBoldText>Device Information </TermsBoldText> such as the
                internet protocol (IP) address, model, keys, operating system
                (OS) version, and dates of creation, update, and authentication,
                device IDs, and login history;
                <br />
                <br />
                <TermsBoldText>Communications Information </TermsBoldText> such
                as your communication choices and the content of your
                correspondence with us (including through chat and email);
                <br />
                <br />
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              Other Information We Collect
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                In addition, we gather additional data in the form of compiled
                statistics that do not specifically identify you. De-identified
                data is the term used to describe this information. When you
                connect with us and use our services and products, we acquire
                de-identified data. Examples may include the number of times a
                user accesses a website or application, the links they click,
                the functions they use, and the kinds of data they contribute.
                To assess the success of our marketing initiatives, we might
                divulge de-identified data to our partners and advertising and
                marketing service providers. Additionally, we might use your
                data to conduct research to better our services and products,
                evaluate how you use our website, and for other purposes.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              How We Collect Your Personal Information
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We collect your Personal Information from the following sources:
                <br />
                <br />
                <TermsBoldText>Direct Communications. </TermsBoldText> When you
                register an account with us, or get in touch with us with
                inquiries or requests, you provide us your personal information.
                <br />
                <br />
                <TermsBoldText>Technological interactions. </TermsBoldText>
                Through cookies and other similar technologies, we may gather
                Device Data and Online Data when you use our applications, or
                visit our website (see Use of Cookies and Other Similar
                Technologies)
                <br />
                <br />
                <TermsBoldText>Third Parties. </TermsBoldText>
                As needed or permitted by applicable legislation, we obtain
                personal information from third parties. Public databases,
                identity verification partners, resellers, and channel partners
                are only a few examples of third-party sources. Additionally, we
                get your personal information from third parties that you've
                given permission for to share it with us if we require it for
                the user.
                <br />
                <br />
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              How We Use Your Personal Data
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                The Company may use Personal Data for the following purposes: To
                provide and maintain our Service, including to monitor the usage
                of our Service.
              </p>
              <TermsListBodyComponent>
                <li>
                  <TermsHeaderComponent>
                    To manage Your Account:
                  </TermsHeaderComponent>
                  <p>
                    To manage Your registration as a user of the Service. The
                    Personal Data You provide can give You access to different
                    functionalities of the Service that are available to You as
                    a registered user.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    For the performance of a contract:
                  </TermsHeaderComponent>
                  <p>
                    The development, compliance and undertaking of the purchase
                    contract for the products, items or services You have
                    purchased or of any other contract with Us through the
                    Service.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>To contact You:</TermsHeaderComponent>
                  <p>
                    To contact You by email, telephone calls, SMS, or other
                    equivalent forms of electronic communication, such as a
                    mobile application's push notifications regarding updates or
                    informative communications related to the functionalities,
                    products or contracted services, including the security
                    updates, when necessary or reasonable for their
                    implementation.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>To provide You</TermsHeaderComponent>
                  <p>
                    With news, special offers and general information about
                    other goods, services and events which we offer that are
                    similar to those that you have already purchased or enquired
                    about unless You have opted not to receive such information.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    To manage Your requests:
                  </TermsHeaderComponent>
                  <p>To attend and manage Your requests to Us.</p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    For business transfers:
                  </TermsHeaderComponent>
                  <p>
                    We may use Your information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Our assets, whether
                    as a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which Personal Data held by Us about
                    our Service users is among the assets transferred.
                  </p>
                </li>
                <li>
                  <TermsHeaderComponent>
                    For other purposes:
                  </TermsHeaderComponent>
                  <p>
                    We may use Your information for other purposes, such as data
                    analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to evaluate
                    and improve our Service, products, services, marketing and
                    your experience.
                  </p>
                </li>
                <br />
                <p>
                  We may share Your personal information in the following
                  situations:
                </p>
                <li>
                  <TermsHeaderComponent>
                    With Service Providers:
                  </TermsHeaderComponent>
                  <p>
                    We may share Your personal information with Service
                    Providers to monitor and analyze the use of our Service, to
                    contact You.{" "}
                  </p>
                </li>
                <li>
                  <TermsHeaderComponent>
                    For business transfers:
                  </TermsHeaderComponent>
                  <p>
                    We may share or transfer Your personal information in
                    connection with, or during negotiations of, any merger, sale
                    of Company assets, financing, or acquisition of all or a
                    portion of Our business to another company.{" "}
                  </p>
                </li>
                <li>
                  <TermsHeaderComponent>
                    With business partners:
                  </TermsHeaderComponent>
                  <p>
                    We may share Your information with Our business partners to
                    offer You certain products, services or promotions.
                    <br />
                    <br />
                    With other users: when You share personal information or
                    otherwise interact in the public areas with other users,
                    such information may be viewed by all users and may be
                    publicly distributed outside.
                    <br />
                    <br />
                    With Your consent: We may disclose Your personal information
                    for any other purpose with Your consent.
                  </p>
                </li>
              </TermsListBodyComponent>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Log Files</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Penta follows a standard procedure of using log files. These
                files log visitors when they use an app. All hosting companies
                do this and are a part of hosting services' analytics. The
                information collected by log files includes internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Cookies and Web Beacons</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Like any other website, Penta uses 'cookies'. These cookies are
                used to store information including visitors' preferences, and
                the pages on the website that the visitor accessed or visited.
                The information is used to optimize the users' experience by
                customizing our web page content based on visitors browser type
                and/or other information.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              Advertising Partners Privacy Policies
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Penta.
                <br />
                <br />
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on Penta, which
                are sent directly to users' browser. They automatically receive
                your IP address when this occurs. These technologies are used to
                measure the effectiveness of their advertising campaigns and/or
                to personalize the advertising content that you see on websites
                that you visit.
                <br />
                <br />
                Note that Penta has no access to or control over these cookies
                that are used by third-party advertisers.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              Third-Party Privacy Policies
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Penta's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions about how to opt-out of certain options.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Under the CCPA, among other rights, California consumers have
                the right to:
                <br />
                &nbsp; &#8226; Request that a business that collects a
                consumer's personal data disclose the categories and specific
                pieces of personal data that a business has collected about
                consumers.
                <br />
                &nbsp; &#8226; Request that a business delete any personal data
                about the consumer that a business has collected.
                <br />
                &nbsp; &#8226; Request that a business that sells a consumer's
                personal data, not sell the consumer's personal data.
                <br />
                <br />
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              GDPR Data Protection Rights
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>

              <TermsListBodyComponent>
                <li>
                  <TermsHeaderComponent>
                    The right to access
                  </TermsHeaderComponent>
                  <p>
                    You have the right to request copies of your personal data.
                    We may charge you a small fee for this service.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    The right to rectification
                  </TermsHeaderComponent>
                  <p>
                    You have the right to request that we correct any
                    information you believe is inaccurate. You also have the
                    right to request that we complete the information you
                    believe is incomplete.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    The right to erasure
                  </TermsHeaderComponent>
                  <p>
                    You have the right to request that we erase your personal
                    data, under certain conditions.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    The right to restrict processing
                  </TermsHeaderComponent>
                  <p>
                    You have the right to request that we restrict the
                    processing of your personal data, under certain conditions.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    The right to object to processing
                  </TermsHeaderComponent>
                  <p>
                    You have the right to object to our processing of your
                    personal data, under certain conditions.
                  </p>
                </li>

                <li>
                  <TermsHeaderComponent>
                    The right to data portability
                  </TermsHeaderComponent>
                  <p>
                    You have the right to request that we transfer the data that
                    we have collected to another organization, or directly to
                    you, under certain conditions.
                  </p>
                </li>

                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </p>
              </TermsListBodyComponent>
            </TermsTextBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>
              Retention of Your Personal Data
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                <br />
                <br />
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              Transfer of Your Personal Data
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
                <br />
                <br />
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
                <br />
                <br />
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              Delete Your Personal Data
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                You have the right to delete or request that We assist in
                deleting the Personal Data that We have collected about You.
                <br />
                <br />
                Our Service may give You the ability to delete certain
                information about You from within the Service.
                <br />
                <br />
                You may update, amend, or delete Your information at any time by
                signing in to Your Account, if you have one, and visiting the
                account settings section that allows you to manage Your personal
                information. You may also contact Us to request access to,
                correct, or delete any personal information that You have
                provided to Us.
                <br />
                <br />
                To delete your account in the app you can follow these steps:
                <br />
                1) Go to My Profile
                <br /> 2) Select &#62; My Account
                <br /> 3) Select &#62; Delete account
                <br /> 4) Confirm &#62; Yes
                <br />
                <br />
                Before you delete your account you will be prompted to withdraw
                all your funds and any amount lesser than the withdrawal fees
                will be lost and your profile data will be totally deleted from
                our records, your account will no longer be visible anywhere
                inside the app. If you choose to join Penta again you will need
                to create a new account.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>
              Disclosure of Your Personal Data
            </TermsHeaderComponent>

            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>
                  Business Transactions
                </TermsHeaderComponent>
                <p>
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Law enforcement</TermsHeaderComponent>
                <p>
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Other legal requirements
                </TermsHeaderComponent>
                <p>
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                  <br />
                  &nbsp; &#8226; Comply with a legal obligation
                  <br />
                  &nbsp; &#8226; Protect and defend the rights or property of
                  the Company
                  <br />
                  &nbsp; &#8226; Prevent or investigate possible wrongdoing in
                  connection with the Service
                  <br />
                  &nbsp; &#8226; Protect the personal safety of Users of the
                  Service or the public
                  <br />
                  &nbsp; &#8226; Protect against legal liability
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>
              Security of Your Personal Data
            </TermsHeaderComponent>

            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security. However, if there is a
              breach involving your personal information, we will let you know
              about it and let you know what steps we have done to fix the
              problem and what security measures we have implemented to make
              your personal information unreadable.
            </p>

            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>
                  Children's Information
                </TermsHeaderComponent>
                <p>
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                  <br />
                  <br />
                  Penta does not knowingly collect any Personal Identifiable
                  Information from children under the age of 13. If you think
                  that your child provided this kind of information on our
                  website, we strongly encourage you to contact us immediately
                  and we will do our best efforts to promptly remove such
                  information from our records.
                </p>
              </li>
            </TermsListBodyComponent>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us at
              support@penta.money{" "}
            </p>
            <br />
            <br />
            <p>
              Penta is a registered brand and trading name of Penta Technologies
              Inc. Penta Technologies Inc is a corporation and a registered
              trademark in the state of Delaware, United States, with its
              registered office at 8 The Green, Ste A, Dover, DE 19901.
            </p>
            <br />
            <br />
            <p>2023 © Penta.</p>
          </TermsContainer>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;

import React from "react";
import "./buttons.scss";

export const PryButton = ({ children, click }) => {
  return (
    <button onClick={click} className="button primary">
      {children}
    </button>
  );
};

export const SecButton = ({ text, disabled, click }) => {
  return (
    <button onClick={click} disabled={disabled} className="button secondary">
      {text}
    </button>
  );
};

export const TextButton = ({ text, click }) => {
  return (
    <button onClick={click} className="button text">
      {text}
    </button>
  );
};

export const CustomButton = ({ content, click }) => {
  return (
    <button onClick={click} className="button custom">
      {content}
    </button>
  );
};

import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation } from "react-router-dom";
import { PryButton } from "../Buttons/Buttons";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as Lightning } from "../../assets/svg/lightning.svg";
import "./navbar.scss";

export default function Navbar({ showMobileMenu, setShowMobileMenu }) {
  const location = useLocation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [navVisible, setNavVisible] = useState(true);
  const [navBg, setNavBg] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setNavVisible(prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, navVisible]);

  const changeBackground = () => {
    if (window.scrollY >= 85) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <section
      className={`nav-container ${navVisible || showMobileMenu ? "" : "hide"} ${
        navBg ? "add-bg" : ""
      }`}
    >
      <div className="wrapper desktop-max-width">
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <ul className="nav-links">
          <li>
            <HashLink smooth to="/#home">
              Home
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#why-penta">
              Why Penta
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#features">
              Features
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#faq">
              FAQ
            </HashLink>
          </li>
        </ul>

        <div className={`nav-btns ${location.pathname !== "/" ? "p23" : ""}`}>
          <div>
            <a
              href="https://onelink.to/mzxnbj"
              target="_blank"
              rel="noreferrer"
            >
              <PryButton>
                ️<Lightning /> <span>Download the App</span>
              </PryButton>
            </a>
          </div>
        </div>

        <div
          className="hamburger-menu"
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
        >
          <div className={`hamburger ${showMobileMenu ? "open" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
          className={`mobile-menu ${showMobileMenu ? "" : "off"}`}
        >
          <div
            className={showMobileMenu ? "wrapper-mobile on" : "wrapper-mobile"}
          >
            <ul className="nav-links-mobile">
              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <HashLink smooth to="/#home">
                  Home
                </HashLink>
              </li>

              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <HashLink smooth to="/#why-penta">
                  Why Penta
                </HashLink>
              </li>
              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <HashLink smooth to="/#features">
                  Features
                </HashLink>
              </li>
              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <HashLink smooth to="/#faq">
                  FAQ
                </HashLink>
              </li>

              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <a
                  href="https://onelink.to/mzxnbj"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PryButton>
                    ️<Lightning /> <span>Download the App</span>
                  </PryButton>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

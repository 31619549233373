import React, { useState } from "react";
import { ReactComponent as Plus } from "../../../assets/svg/faq-plus.svg";
import "./faqs.scss";

const Faqs = () => {
  const [active, setActive] = useState(null);

  const faqs = [
    {
      question: "How does Penta Work?",
      answer: (
        <div>
          <p>
            Penta offers a suite of products and services that help you save,
            invest, access credit, pay bills and make transfers to family and
            friends. You can start by downloading the app from your mobile app
            store, signup and start saving today.{" "}
          </p>
          <h3>Sign up Process:</h3>
          <h3>(Email Verification) Failure to receive OTP in user’s mail</h3>
          <p>
            This shouldn’t have happened. However, you can try the following
            options:
          </p>
          <ol>
            <li>
              Please make sure that you have typed the correct email address
              while signing up
            </li>
            <li>Wait for 30 seconds and resend again</li>
            <li>Check your spam/junk folder like promotions/social updates.</li>
          </ol>

          <h3> (Phone Number Verification) Failure to receive OTP</h3>
          <p>Sorry about this, please try the following options:</p>
          <ol>
            <li>
              Confirm that you have typed in the correct phone number while
              signing up
            </li>
            <li>Wait for 30 seconds and resend the OTP again</li>
          </ol>

          <h3>(Phone Number Verification) Could not reset Password </h3>
          <p>
            When resetting Password, always ensure that you have typed the same
            password in the column provided for the New Password and
            Confirmation of the new password.
          </p>
        </div>
      ),
    },
    {
      question: "What do I need to complete Penta KYC?",
      answer: (
        <div>
          <p>
            At Penta, we've made our KYC processes easy with your local identity
            cards like a driver's license, NIN, international passports etc .
            Using your ID for KYC is important to prevent impersonation and
            avoid withdrawal limitations.
          </p>
          <h3>KYC Completion Process:</h3>
          <h3>Unable to verify BVN </h3>
          <p>
            Please ensure that you have typed in the correct 11 digit BVN
            number.{" "}
          </p>
        </div>
      ),
    },
    {
      question: "How does the Penta Card work?",
      answer:
        "Setting up a card on Penta costs $5. The cards can be used for transactions anywhere in the world.",
    },
    {
      question: "How long does a transfer take to settle?",
      answer: "Transfers take a maximum of 3 minutes to settle.",
    },
    {
      question: "How do I know the right savings plan for me?",
      answer: (
        <div>
          <p>
            Penta's savings and investment choices are all suited to a specific
            requirement.
          </p>
          <p>
            Penta Target Savings is an excellent option if you're saving for a
            specific goal, like rent or travel.
          </p>
          <p>
            If you're searching for something long-term, The Penta Vault feature
            is great for you. With greater interest rates, you may put away
            funds you don't need and earn even more money.
          </p>
        </div>
      ),
    },
    {
      question: "How can I create Vault?",
      answer: (
        <div>
          <p>To create Vault, please follow the steps below:</p>
          <ol>
            <li>Log into your Penta app, Click on Save tab</li>
            <li>Click on Vault and then create a vault</li>
            <li>Select the yield period</li>
            <li>Select the vault category</li>
            <li>Input the Vault name</li>
            <li>Enter the amount and purpose for saving</li>
            <li>Click complete</li>
            <li>
              Enter your Pin code, to finish the process of vault creation.
            </li>
          </ol>
        </div>
      ),
    },
    {
      question: "How To Transfer Stash Fund to another Penta User?",
      answer: (
        <div>
          <p>Fund Transfer, please follow the steps below:</p>
          <ol>
            <li>Log into your Penta app, Click on Save tab</li>
            <li>Click on Withdrawal</li>
            <li>Select withdrawal method i.e Transfer To Penta User</li>
            <li>Enter the Penta user tag i.e (cenudi3975)</li>
            <li>Specify the narration</li>
            <li>Enter the amount</li>
            <li>Click continue</li>
            <li>Enter your Pin code, to finish the transfer process</li>
          </ol>
        </div>
      ),
    },
    {
      question: "How do I delete my Penta Account?",
      answer: (
        <div>
          <p>
            Let me guess, you are no longer interested in the services provided
            by Penta Money and you want to delete your account. We are happy to
            help! At this time you can only delete your Penta account from
            inside your app.
          </p>
          <p>Please follow these steps:</p>
          <ol>
            <li>Go to My Profile</li>
            <li>Select {">"} My Account</li>
            <li>Select {">"} Delete account</li>
            <li>Confirm {">"} Yes</li>
          </ol>
          <p>
            Before you delete your account you will be prompted to withdraw all
            your funds and any amount less than the withdrawal fees will be lost
            and your profile data will be totally deleted from our records, your
            account will no longer be visible anywhere inside the app. If you
            choose to join Penta again you will need to create a new account.
          </p>

          <h3>
            NOTE: Feel free to contact support@penta.money, If you still have
            any questions or issues after following the processes listed above.
          </h3>
        </div>
      ),
    },
  ];
  return (
    <section className="faqs" id="faq">
      <div className="faqs-wrapper desktop-max-width">
        <div className="f-row-one">Frequently Asked Questions</div>

        <div className="f-row-two">
          If others are asking, you are most likely thinking the same thing.
          Here are some possible answers.
        </div>

        <div className="faqs-main">
          {faqs.map((item, index) => (
            <div className="faq-item" key={index}>
              <div
                className="f-i-top click no-select"
                onClick={() => {
                  if (index === active) {
                    setActive(null);
                  } else {
                    setActive(index);
                  }
                }}
              >
                <div className="i-question">{item.question}</div>
                <div>
                  <Plus />
                </div>
              </div>
              {active === index && (
                <div
                  className={`f-i-bottom ${
                    active === index ? "is-active" : ""
                  }`}
                  data-aos="fade-up"
                >
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;

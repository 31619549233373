import React from "react";
import Banner from "./Banner/Banner";
import WhyPenta from "./WhyPenta/WhyPenta";
import WeAreEverywhere from "./WeAreEverywhere/WeAreEverywhere";
import Faqs from "./Faqs/Faqs";
import DownloadPenta from "./DownloadPenta/DownloadPenta";
import Features from "./Features/Features";
import { ReactComponent as BannerVect } from "../../assets/svg/banner-vect.svg";
import "./landing.scss";

const Landing = () => {
  return (
    <div className="landing-page">
      <BannerVect className="b-vect" />
      <Banner />
      <WhyPenta />
      <Features />
      <WeAreEverywhere />
      <Faqs />
      <DownloadPenta />
    </div>
  );
};

export default Landing;

import React from "react";
import Woman from "../../../assets/png/woman-holding-phone.png";
import Man from "../../../assets/png/man-holding-phone.png";
import { ReactComponent as Polygon } from "../../../assets/svg/polygon.svg";
import { ReactComponent as Stores } from "../../../assets/svg/stores.svg";
import { ReactComponent as Vect3 } from "../../../assets/svg/vect3.svg";
import { ReactComponent as Vect4 } from "../../../assets/svg/vect4.svg";
import { PryButton } from "../../../components/Buttons/Buttons";
import "./why-penta.scss";

const WhyPenta = () => {
  return (
    <section className="why-penta" id="why-penta">
      <Vect3 className="vect3" />
      <Vect4 className="vect4" />

      <div className="w-p-inner desktop-max-width">
        <div className="w-p-top">
          <div className="badge">Get to know more about us.</div>
          <div className="t-title">Why Penta?</div>
        </div>

        <div className="w-p-features">
          <div className="feature">
            <div className="img-wrapper">
              <img src={Woman} alt="" />
            </div>

            <div
              className="f-content"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="c-row-one">
                <Polygon />
                <span>Seamless</span>
              </div>

              <div className="c-row-two">
                PENTA is your one stop solution for all your financial needs.
              </div>

              <div className="c-row-three">
                We have a diverse portfolio of savings, investment, lending and
                credit services that help you make the most of your money. And
                enable you to turn idle cash into a secure source of regular
                income.
              </div>

              <a
                href="https://onelink.to/mzxnbj"
                target="_blank"
                rel="noreferrer"
              >
                <PryButton>Get the app</PryButton>
              </a>
            </div>
          </div>

          <div className="feature inverse">
            <div className="img-wrapper">
              <img src={Man} alt="" />
            </div>

            <div
              className="f-content"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="c-row-one">
                <Polygon />
                <span>Diverse</span>
              </div>

              <div className="c-row-two">
                Financial Freedom at your fingertips
              </div>

              <div className="c-row-three">
                Pick the Penta Vault savings option that best suits you from
                among our numerous savings options.
              </div>

              <a
                href="https://onelink.to/mzxnbj"
                target="_blank"
                rel="noreferrer"
              >
                <PryButton>
                  <Stores />
                  Download the app
                </PryButton>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyPenta;

import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./components/Navbar/Navbar";
import Landing from "./pages/Landing/Landing";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import Footer from "./components/Footer/Footer";

import "./App.scss";

function App() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  AOS.init();

  return (
    <div className={`App ${showMobileMenu ? "mobile-active" : ""}`}>
      <Navbar {...{ showMobileMenu, setShowMobileMenu }} />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

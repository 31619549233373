import React from "react";
import BImgLg from "../../../assets/png/banner-image-lg.png";
import AppStore from "../../../assets/svg/app-store.svg";
import PlayStore from "../../../assets/svg/play-store.svg";
import Credit from "../../../assets/png/credit.png";
import Bill from "../../../assets/png/bill.png";
import Savings from "../../../assets/png/savings.png";
import Returns from "../../../assets/png/returns.png";
import Lending from "../../../assets/png/lending.png";
import { ReactComponent as Vect } from "../../../assets/svg/access-vect.svg";
import "./banner.scss";

const Banner = () => {
  return (
    <section className="banner" id="home">
      <div
        className="banner-wrapper desktop-max-width"
        data-aos="fade"
        data-aos-duration="2000"
      >
        <div className="b-row-one">
          Save. <span>Grow.</span> Build.
        </div>

        <div className="b-row-two">
          Penta can help you grow your earnings & build a better financial
          future. Each feature provides a pathway to financial stability.
        </div>

        <img
          className="b-img"
          src={BImgLg}
          height="548"
          width="816"
          alt="mockup"
        />

        <div className="store-btns">
          <a
            href="https://apps.apple.com/ng/app/penta-money/id6443537094"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppStore} alt="download from app store" />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=penta.money.penta"
            target="_blank"
            rel="noreferrer"
          >
            <img src={PlayStore} alt="download from google play store" />
          </a>
        </div>
      </div>

      <div className="gain-access desktop-max-width">
        <div className="g-a-inner" data-aos="fade-up" data-aos-duration="1000">
          <Vect className="access-vect" />
          <div className="g-a-title">You gain access to</div>

          <div className="g-a-items">
            <div className="a-item">
              <img src={Savings} alt="savings calculator" />
              <div>Simplified savings.</div>
            </div>

            <div className="a-item">
              <img src={Bill} alt="bill payments" />
              <div>Bill payments and transfer</div>
            </div>

            <div className="a-item">
              <img src={Returns} alt="high yield returns" />
              <div>High yield returns</div>
            </div>

            <div className="a-item">
              <img src={Credit} alt="access to credit" />
              <div>Access to credit</div>
            </div>

            <div className="a-item">
              <img src={Lending} alt="lending" />
              <div>Institutional grade lending</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

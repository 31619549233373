import React, { useState, Fragment, useEffect } from "react";
import { ReactComponent as Build } from "../../../assets/svg/features/build.svg";
import { ReactComponent as Cards } from "../../../assets/svg/features/cards.svg";
import { ReactComponent as Lending } from "../../../assets/svg/features/lending.svg";
import { ReactComponent as Payments } from "../../../assets/svg/features/payments.svg";
import { ReactComponent as Wallet } from "../../../assets/svg/features/wallet-money.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/svg/arrow-right.svg";
import { ReactComponent as One } from "../../../assets/svg/features/one.svg";
import { ReactComponent as OneActive } from "../../../assets/svg/features/one-active.svg";
import { ReactComponent as Two } from "../../../assets/svg/features/two.svg";
import { ReactComponent as TwoActive } from "../../../assets/svg/features/two-active.svg";
import { ReactComponent as Three } from "../../../assets/svg/features/three.svg";
import { ReactComponent as ThreeActive } from "../../../assets/svg/features/three-active.svg";
import { ReactComponent as Four } from "../../../assets/svg/features/four.svg";
import { ReactComponent as FourActive } from "../../../assets/svg/features/four-active.svg";
import { ReactComponent as Five } from "../../../assets/svg/features/five.svg";
import { ReactComponent as FiveActive } from "../../../assets/svg/features/five-active.svg";
import build from "../../../assets/png/features/build.png";
import card from "../../../assets/png/features/card.png";
import lending from "../../../assets/png/features/lending.png";
import payments from "../../../assets/png/features/payments.png";
import save from "../../../assets/png/features/save.png";
import "./features.scss";

const Features = () => {
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);

    setTimeout(() => {
      setShow(true);
    }, 1);
  }, [active]);

  useEffect(() => {
    const autoScroll = setInterval(() => {
      setActive(active + 1);
      if (active === 4) {
        setActive(0);
      }
    }, 12000);
    return () => clearInterval(autoScroll);
  }, [active]);

  const features = [
    {
      icon: <Wallet />,
      title: "SAVE",
      desc: "With our easy-to-use interface, you can start saving money right away. Plus, our competitive interest rates mean you'll earn more on your savings than you would with a traditional savings account.",
      number: active === 0 ? <OneActive /> : <One />,
      img: save,
    },
    {
      icon: <Cards />,
      title: "CARD",
      desc: "You're in charge. With Penta, you can create and manage unlimited cards for any use case. Whether it's a physical or virtual card, a debit card or a credit card, we've got you covered.",
      number: active === 1 ? <TwoActive /> : <Two />,
      img: card,
    },
    {
      icon: <Build />,
      title: "BUILD",
      desc: "Penta uses a diversified approach to investing, with a focus on asset allocation, so you can grow your wealth over time.",
      number: active === 2 ? <ThreeActive /> : <Three />,
      img: build,
    },
    {
      icon: <Payments />,
      title: "INTERNATIONAL PAYMENTS",
      desc: "Get rid of the hassles and restrictions of cross-border payments. With Transfers, you can send money and pay bills to other African countries at the best rates.",
      number: active === 3 ? <FourActive /> : <Four />,
      img: payments,
    },
    {
      icon: <Lending />,
      title: "BORROWING AND LENDING",
      desc: "Get more bang for your buck with Penta. We're here to make life easier with our innovative way of giving you access to credit on your terms. We offer flexible repayments and interest rates so you can get the most out of your money!",
      number: active === 4 ? <FiveActive /> : <Five />,
      img: lending,
    },
  ];

  return (
    <section className="features" id="features">
      <div className="features-wrapper desktop-max-width">
        <div className="f-title">
          The Features Were Crafted to Meet Your Needs
        </div>

        {show && (
          <div className="f-main" data-aos="fade" data-aos-duration="1500">
            <div className="f-m-left">
              <div className="ctrl-btns">
                <ArrowLeft
                  className={active === 0 ? "b-disabled" : "click"}
                  onClick={() => {
                    if (active !== 0) {
                      setActive(active - 1);
                    }
                  }}
                />
                <ArrowRight
                  className={active === 4 ? "b-disabled" : "click"}
                  onClick={() => {
                    if (active !== 4) {
                      setActive(active + 1);
                    }
                  }}
                />
              </div>

              <div className="f-i-icon">{features[active].icon}</div>
              <div className="f-i-title">{features[active].title}</div>
              <div className="f-i-desc">{features[active].desc}</div>
            </div>

            <div className="f-m-right">
              <img src={features[active].img} alt="" />
            </div>
          </div>
        )}

        <div className="numbers">
          {features.map((item, index) => (
            <Fragment key={index}>{item.number}</Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;

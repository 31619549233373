import React from "react";
import Country1 from "../../../assets/png/countries/country-1.png";
import Country2 from "../../../assets/png/countries/country-2.png";
import Country3 from "../../../assets/png/countries/country-3.png";
import Country4 from "../../../assets/png/countries/country-4.png";
import Country5 from "../../../assets/png/countries/country-5.png";
import { PryButton } from "../../../components/Buttons/Buttons";
import "./we-are-everywhere.scss";

const WeAreEverywhere = () => {
  return (
    <div className="we-are-everywhere">
      <div className="w-a-e-wrapper desktop-max-width">
        <div className="w-row-one">We Are Everywhere</div>

        <div className="w-row-two">
          No matter where you are in the world, you can use the Penta app to
          access high-yield returns on your savings, pay bills, and send money
          to family and friends in over 190 countries.
        </div>

        <div className="w-row-three">
          <img src={Country1} alt="nigria" />
          <img src={Country5} alt="USA" />
          <img src={Country2} alt="country" />
          <img src={Country3} alt="country" />
          <img src={Country4} alt="country" />
        </div>

        <a href="https://onelink.to/mzxnbj" target="_blank" rel="noreferrer">
          <PryButton>Download App</PryButton>
        </a>
      </div>
    </div>
  );
};

export default WeAreEverywhere;

import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as Instagram } from "../../assets/svg/socials/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/svg/socials/twitter.svg";
import { ReactComponent as Mail } from "../../assets/svg/socials/mail.svg";
import { ReactComponent as PVector } from "../../assets/svg/p-vector.svg";
import "./footer.scss";

const Footer = () => {
  return (
    <section className="footer">
      <PVector className="p-vect" />
      <PVector className="p-vect two" />
      <div className="footer-wrapper desktop-max-width">
        <div className="f-top">
          <div className="f-t-left">
            <Logo />

            <p>
              Penta is a registered brand and trading name of Penta Technologies
              Inc. Penta Technologies Inc is a corporation and a registered
              trademark in the state of Delaware, United States, with its
              registered office at 8 The Green, Ste A, Dover, DE 19901.
            </p>
          </div>

          <div className="f-t-right">
            <Link to="/terms">
              <div className="click">Terms and Conditions</div>
            </Link>
            <Link to="/privacy">
              <div className="click">Privacy Policy</div>
            </Link>

            <div className="socials">
              <a
                href="https://twitter.com/PentaMoney"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter className="click" />
              </a>
              <a
                href="https://www.instagram.com/pentamoney/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram className="click" />
              </a>
              <a href="mailto:support@penta.money">
                <Mail className="click" />
              </a>
            </div>
          </div>
        </div>

        <div className="f-bottom">{new Date().getFullYear()} &#169; Penta.</div>
      </div>
    </section>
  );
};

export default Footer;

import React from "react";
import Mockup from "../../../assets/png/pixels-mockup.png";
import { ReactComponent as Scan } from "../../../assets/svg/scan.svg";
import { ReactComponent as Stores } from "../../../assets/svg/stores-dark.svg";
import { PryButton } from "../../../components/Buttons/Buttons";
import "./download-penta.scss";

const DownloadPenta = () => {
  return (
    <section className="download">
      <div className="download-wrapper desktop-max-width">
        <div className="d-left">
          <div className="l-text">
            Download Penta <span>to access financial freedom</span>
          </div>

          <a href="https://onelink.to/mzxnbj" target="_blank" rel="noreferrer">
            <PryButton>
              <Stores /> Download the app
            </PryButton>
          </a>
        </div>

        <div className="d-center">
          <img src={Mockup} alt="Mockup" />
        </div>

        <div className="d-right">
          <Scan />
        </div>
      </div>
    </section>
  );
};

export default DownloadPenta;
